/**
 * eslint-disable react/jsx-props-no-spreading
 *
 * @format
 */

import { DatePicker } from '@mui/x-date-pickers';
import { Checkbox, Tooltip } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import _ from 'lodash';
import moment from 'moment';
import { enqueueSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { api } from '../../../api.ts';
import { parseFieldError } from '../../../utils/errors';
import CustomAutocomplete from '../../misc/CustomAutocomplete';
import RequiredFieldAdornment from './RequiredFieldAdornment';

const defaultEndpoints = {
  lead: (parent, split) =>
    `/leads${
      split && split.length > 1
        ? `-${split[0].replace('_', '-')}${!split[0].endsWith('s') ? 's' : ''}`
        : ''
    }/${parent.id}/`,
  site: (parent, split) =>
    `/leads${
      split && split.length > 1
        ? `-${split[0].replace('_', '-')}${!split[0].endsWith('s') ? 's' : ''}`
        : '-sites'
    }/${parent.id}/`,
  client: (parent, split) =>
    `/clients${
      split && split.length > 1
        ? `-${split[0].replace('_', '-')}${!split[0].endsWith('s') ? 's' : ''}`
        : ''
    }/${parent.id}/`,
  quote: (parent, split) =>
    `/quotes${
      split && split.length > 1
        ? `-${split[0].replace('_', '-')}${!split[0].endsWith('s') ? 's' : ''}`
        : ''
    }/${parent.id}/`,
};

class AutoSaveField extends Component {
  constructor(props) {
    super(props);

    if (
      (props.select && props.autoComplete) ||
      (props.select && props.boolean) ||
      (props.boolean && props.autoComplete)
    ) {
      throw new Error(
        'select / autoComplete / boolean cannot be used at the same time.',
      );
    }

    this.state = {
      value: this.getDefaultValue(),
      secondaryValue: this.getDefaultValue(true),
      tertiaryValue: this.getDefaultValue(false, true),
      error: null,
      loading: false,
      isWriting: false,
    };

    this.getDefaultValue = this.getDefaultValue.bind(this);
    this.handleWriting = this.handleWriting.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  /* shouldComponentUpdate(nextProps) {
    const { parent, field } = this.props;
    const split = field.split('.');

    return (split.length > 1
      && !_.isEqual(nextProps.parent[split[0]][split[1]], parent[split[0]][split[1]]))
      || (split.length <= 1 && !_.isEqual(nextProps.parent[field], parent[field]));
  } */

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { parent, field, secondaryField, tertiaryField } = this.props;
    const { isWriting } = this.state;
    const split = field.split('.');
    if (
      (split.length > 1 &&
        !_.isEqual(
          prevProps.parent[split[0]][split[1]],
          parent[split[0]][split[1]],
        )) ||
      (split.length <= 1 && !_.isEqual(prevProps.parent[field], parent[field]))
    ) {
      if (isWriting) {
        setTimeout(() => {
          this.componentDidUpdate(prevProps, prevState, snapshot);
        }, 1000);
      } else {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ value: this.getDefaultValue() });
      }
    }

    if (secondaryField) {
      const secondarySplit = secondaryField.split('.');
      if (
        (secondarySplit.length > 1 &&
          !_.isEqual(
            prevProps.parent[secondarySplit[0]][secondarySplit[1]],
            parent[secondarySplit[0]][secondarySplit[1]],
          )) ||
        (secondarySplit.length <= 1 &&
          !_.isEqual(prevProps.parent[secondaryField], parent[secondarySplit]))
      ) {
        if (isWriting) {
          setTimeout(() => {
            this.componentDidUpdate(prevProps, prevState, snapshot);
          }, 1000);
        } else {
          // eslint-disable-next-line react/no-did-update-set-state
          this.setState({ secondaryValue: this.getDefaultValue(true) });
        }
      }
    }
    if (tertiaryField) {
      const tertiarySplit = tertiaryField.split('.');
      if (
        (tertiarySplit.length > 1 &&
          !_.isEqual(
            prevProps.parent[tertiarySplit[0]][tertiarySplit[1]],
            parent[tertiarySplit[0]][tertiarySplit[1]],
          )) ||
        (tertiarySplit.length <= 1 &&
          !_.isEqual(prevProps.parent[tertiaryField], parent[tertiarySplit]))
      ) {
        if (isWriting) {
          setTimeout(() => {
            this.componentDidUpdate(prevProps, prevState, snapshot);
          }, 1000);
        } else {
          this.setState({ tertiaryValue: this.getDefaultValue(false, true) });
        }
      }
    }
  }

  /**
   * Handles writing state to manage timeout
   * @returns {Promise<void>}
   */
  async handleWriting() {
    clearTimeout(this.writingTimeout);
    this.setState({ isWriting: true });
    this.writingTimeout = setTimeout(() => {
      this.setState({ isWriting: false });
    }, 2000);
  }

  /**
   * Handles input change, patch after timeout
   * @param event {Event} Event
   */
  handleInputChange(event) {
    if (!event) return;
    const { value } = event.target;
    const { secondary, tertiary } = event.target;
    this.handleWriting();
    if (!secondary && !tertiary) {
      this.setState({
        value,
        loading: true,
      });
    } else if (secondary) {
      this.setState({
        secondaryValue: value,
        loading: true,
      });
    } else if (tertiary) {
      this.setState({
        tertiaryValue: value,
        loading: true,
      });
    }
    clearTimeout(this.timeout);
    const { autoComplete, boolean } = this.props;
    this.timeout = setTimeout(
      () => {
        const {
          field: primaryField,
          secondaryField,
          tertiaryField,
          parent,
          onChange,
          number,
          date,
          parentType,
          customEndpoint,
          multiple,
        } = this.props;
        let field;
        if (tertiary) {
          field = tertiaryField;
        } else if (secondary) {
          field = secondaryField;
        } else {
          field = primaryField;
        }
        if (!field) {
          this.setState({ loading: false });
          return;
        }
        let data;
        const split = field.split('.');
        let val = value;
        if (val === '') {
          val = null;
        }
        if (number && val) {
          val = Number(val.replace(/,/gi, '.')).toFixed(2);
        }
        if (date && val) {
          val = moment(val).format('YYYY-MM-DD');
        }
        if (autoComplete && val) {
          val = multiple ? val.map((v) => v.id) || [] : val.id;
        }
        if (split.length > 1) {
          data = {
            [split[1]]: val,
          };
        } else {
          data = {
            [field]: val,
          };
        }
        if (secondary) {
          data.business_introducer_contact = null;
        }
        const endpoint =
          parentType !== 'custom'
            ? defaultEndpoints[parentType](parent, split)
            : customEndpoint(parent, split);
        api
          .patch(endpoint, {}, data)
          .then((result) => {
            this.setState({ loading: false, error: null }, () => {
              onChange(result.data);
              // TODO: pass result in onChange result
            });
          })
          .catch((err) => {
            const { state, message } = parseFieldError(err, true);
            if (!state || !state.error)
              enqueueSnackbar(message, { variant: 'error' });
            this.setState({ ...state, loading: false });
          });
      },
      autoComplete || boolean ? 0 : 500,
    );
  }

  /**
   * Gets default value
   * @param secondary {boolean} Is a secondary field
   * @param tertiary {boolean} Is a tertiary field
   * @returns {string}
   */
  getDefaultValue(secondary = false, tertiary = false) {
    const {
      parent,
      field: primaryField,
      secondaryField,
      tertiaryField,
      enforcedValue,
    } = this.props;
    if (secondary && !secondaryField) return '';
    if (tertiary && !tertiaryField) return '';
    let field;
    if (tertiary) {
      field = tertiaryField;
    } else if (secondary) {
      field = secondaryField;
    } else {
      field = primaryField;
    }
    const split = field.split('.');
    let value;

    if (split.length > 1) {
      if (parent[split[0]]) {
        value = parent[split[0]][split[1]];
      } else {
        value = '';
      }
    } else {
      value = parent[field];
    }

    if (value === undefined) {
      value = '';
    }

    if (!value || value === '') {
      return enforcedValue;
    }

    return value;
  }

  render() {
    const {
      children,
      label,
      select,
      autoComplete,
      autoCompleteProps,
      options,
      disabled,
      boolean,
      date,
      requiredFor,
      number,
      multiple,
      tooltip,
      color,
      requireCheckbox,
      enforcedValue,
    } = this.props;
    const { value, secondaryValue, tertiaryValue, error, loading } = this.state;
    return (
      <div>
        {children ? (
          children(
            label,
            value,
            this.handleInputChange,
            secondaryValue,
            tertiaryValue,
            error,
            loading,
          )
        ) : (
          <>
            {autoComplete && (
              <CustomAutocomplete
                {...autoCompleteProps}
                label={label}
                value={value}
                size="small"
                onChange={(e, v) =>
                  this.handleInputChange({
                    target: {
                      value: v,
                    },
                  })
                }
                requiredFor={requiredFor}
                multiple={multiple}
                disabled={disabled}
                tooltip={tooltip}
              />
            )}
            {boolean && (
              <FormControlLabel
                control={
                  requireCheckbox ? (
                    <Checkbox
                      checked={value}
                      onChange={(e) =>
                        this.handleInputChange({
                          target: {
                            value: e.target.checked,
                          },
                        })
                      }
                      color={color}
                    />
                  ) : (
                    <Switch
                      checked={value}
                      onChange={(e) =>
                        this.handleInputChange({
                          target: {
                            value: e.target.checked,
                          },
                        })
                      }
                      color={color}
                    />
                  )
                }
                label={label}
                disabled={disabled}
              />
            )}
            {date && (
              <DatePicker
                label={label}
                value={moment(value)}
                onChange={(d) =>
                  this.handleInputChange({
                    target: {
                      value: d,
                    },
                  })
                }
                disabled={disabled}
                format="DD/MM/YYYY"
                slotProps={{
                  textField: {
                    variant: 'outlined',
                    fullWidth: true,
                    size: 'small',
                    error: Boolean(error),
                    helperText: error,
                  },
                }}
              />
            )}
            {!autoComplete && !boolean && !date && (
              <Tooltip
                title={tooltip.content}
                placement={tooltip.placement}
                disableFocusListener={tooltip.disabled}
                disableHoverListener={tooltip.disabled}
                disableTouchListener={tooltip.disabled}
                arrow
              >
                <TextField
                  variant="outlined"
                  fullWidth
                  label={label}
                  value={
                    // eslint-disable-next-line no-nested-ternary
                    number &&
                    value &&
                    value !== '' &&
                    value.toString().includes('.')
                      ? Number(Number(value).toFixed(2))
                          .toString()
                          .replace(/\./gi, ',')
                      : value || ''
                  }
                  onChange={this.handleInputChange}
                  error={Boolean(error)}
                  helperText={error}
                  size="small"
                  disabled={disabled}
                  InputProps={{
                    endAdornment: loading ? (
                      <CircularProgress color="default" size={20} />
                    ) : (
                      <>
                        {requiredFor && (
                          <RequiredFieldAdornment
                            type={value ? 'valid' : requiredFor}
                          />
                        )}
                      </>
                    ),
                  }}
                  select={select}
                >
                  {select && options
                    ? options.map((o) => (
                        <MenuItem value={o.key} key={o.key}>
                          {o.value}
                        </MenuItem>
                      ))
                    : null}
                </TextField>
              </Tooltip>
            )}
          </>
        )}
      </div>
    );
  }
}

AutoSaveField.propTypes = {
  children: PropTypes.func,
  parent: PropTypes.shape().isRequired,
  parentType: PropTypes.oneOf(['lead', 'site', 'client', 'custom']).isRequired,
  field: PropTypes.string.isRequired,
  secondaryField: PropTypes.string,
  tertiaryField: PropTypes.string,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  number: PropTypes.bool,
  integer: PropTypes.bool,
  select: PropTypes.bool,
  boolean: PropTypes.bool,
  phone: PropTypes.bool,
  date: PropTypes.bool,
  autoComplete: PropTypes.bool,
  autoCompleteProps: PropTypes.shape(),
  multiple: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape()),
  requiredFor: PropTypes.oneOf(['quote', 'study']),
  customEndpoint: PropTypes.func,
  tooltip: PropTypes.shape(),
  color: PropTypes.string,
  requireCheckbox: PropTypes.bool,
  enforcedValue: PropTypes.string,
};

AutoSaveField.defaultProps = {
  children: null,
  secondaryField: null,
  tertiaryField: null,
  disabled: false,
  number: false,
  integer: false,
  select: false,
  boolean: false,
  phone: false,
  date: false,
  autoComplete: false,
  autoCompleteProps: null,
  multiple: false,
  options: null,
  requiredFor: null,
  customEndpoint: () => null,
  tooltip: { content: '', placement: '', disabled: true },
  color: 'primary',
  requireCheckbox: false,
  enforcedValue: null,
};

export default AutoSaveField;
